/* Change this file to get your personal Porfolio */
import emoji from "react-easy-emoji";
// Splash Screen
import splashAnimation from "./assests/lottie/splashAnimation"; // Rename to your file name for custom animation

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 1600 // Set animation duration as per your animation
};

// Home image animation
const illustration = {
  animated: true // Set to false to use static SVG
};

//SEO Related settings
const seo = {
  title: "Kevin Medrano's Portfolio",
  subTitle: emoji("Hi There!"),
  description:
    "An enthusiastic Robotics and Automation Engineer who is dedicated to developing comprehensive solutions that drive efficiency and impact.",
  og: {
    title: "Kevin Medrano Ayala Portfolio",
    type: "website",
    url: "https://kmedrano101.github.io",
  },
};

//Home Page
const greeting = {
  title: "Hi there, I'm Kevin",
  logo_name: "Kevin Medrano Ayala",
  nickname: "Kmedrano101",
  subTitle:
    "An enthusiastic Robotics and Automation Engineer who is dedicated to developing comprehensive solutions that drive efficiency and impact. 🚀",
  resumeLink:
    "https://drive.google.com/file/d/1GKlkVj4y7yWXSt_onv4_lzAcKW_ekjr3/view?usp=sharing",
  portfolio_repository: "https://github.com/Kmedrano101/",
  githubProfile: "https://github.com/Kmedrano101",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/Kmedrano101",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/kmedrano101/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/channel/UC36ocOk_f8X_qR7wLlxPNYQ",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:kevin.ejem18@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  /*{
    name: "X-Twitter",
    link: "https://twitter.com/Kmedrano101",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },*/
  {
    name: "Facebook",
    link: "https://www.facebook.com/Kmedrano101/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/kmedrano101_/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Electrical and Electronics",
      fileName: "Electricity",
      skills: [
        "⚡ Experienced technician skilled in organizing, installing, calibrating, and maintaining instruments and equipment across various settings (residential, industrial, domestic).",
        "⚡ Skilled in electronic circuit design and development. Utilizes Proteus and Kicad to create functional and efficient printed circuit boards (PCBs) from schematics to layout design.",
        "⚡ A proven track record in configuring and integrating smart devices such as lighting, thermostats, locks, audio and video, among others.",
      ],
      softwareSkills: [
        {
          skillName: "Electricity",
          fontAwesomeClassname: "healthicons:electricity",
          style: {
            backgroundColor: "transparent",
            color: "#ffd60a",
          },
        },
        {
          skillName: "Electronics",
          fontAwesomeClassname: "flat-color-icons:electronics",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Circuit Design",
          fontAwesomeClassname: "ph:circuitry-fill",
          style: {
            backgroundColor: "transparent",
            color: "#008000",
          },
        },
        {
          skillName: "Schematics and Diagrams",
          fontAwesomeClassname: "openmoji:circuit",
          style: {
            backgroundColor: "transparent",
            //color: "#3776AB",
          },
          /*imageSrc: "deeplearning_ai_logo.png",*/ // Example how to import an image
        },
        {
          skillName: "Arduino",
          fontAwesomeClassname: "devicon:arduino-wordmark",
          style: {
            backgroundColor: "transparent",
            //color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Robotics and Industrial Automation",
      fileName: "Robotics",
      skills: [
        "⚡ Leveraging knowledge of robotics principles and industry best practices to design efficient and effective robotic systems.",
        "⚡ Utilizing various programming languages and frameworks to program robots and industrial automation equipment.",
        "⚡ Skilled in integrating robots and automation systems with existing infrastructure and control systems to ensure seamless operation, fostering an Industry 4.0 environment.",
      ],
      softwareSkills: [
        {
          skillName: "C++",
          fontAwesomeClassname: "devicon:cplusplus",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "devicon:python",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Robot Operating System",
          fontAwesomeClassname: "devicon:ros",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Rockwell Automation",
          fontAwesomeClassname: "simple-icons:rockwellautomation",
          style: {
            backgroundColor: "transparent",
            color: "#d00000",
          },
        },
        {
          skillName: "Siemens Automation",
          imageSrc: "siemens.png",
        },
        {
          skillName: "Gazebo",
          fontAwesomeClassname: "devicon:gazebo",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Festo Pneumatics",
          imageSrc: "festo.png",
        },
      ],
    },
    {
      title: "ML and DL Algorithms",
      fileName: "MachineLearning",
      skills: [
        "⚡ Skilled in developing and deploying Machine Learning models using popular libraries such as TensorFlow and Scikit-learn.",
        "⚡ Experienced in building and deploying Deep Learning models for various applications, utilizing frameworks like TensorFlow and PyTorch.",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
      ],
      softwareSkills: [
        {
          skillName: "Scikit-Learn",
          fontAwesomeClassname: "devicon:scikitlearn",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Tensorflow",
          fontAwesomeClassname: "logos-tensorflow",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Deeplearning",
          imageSrc: "deeplearning_ai_logo.png",
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "logos:postgresql",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "SQL Server",
          fontAwesomeClassname: "devicon:microsoftsqlserver",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
    {
      title: "Project Management",
      fileName: "ProjectManagement",
      skills: [
        "⚡ Implementation of Agile methodologies like Scrum and Kanban to facilitate team collaboration, adaptation to change, and incremental value delivery.",
        "⚡ Proactive identification and assessment of potential risks, with mitigation plans to minimize project impact.",
        "⚡ Leverage of tools like Scrum boards, task management software (Trello, Jira), and communication platforms to ensure efficient project management and team collaboration.",
      ],
      softwareSkills: [
        {
          skillName: "Teams",
          fontAwesomeClassname: "logos:microsoft-teams",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Google",
          fontAwesomeClassname: "logos:google-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Microsoft Tools",
          fontAwesomeClassname: "logos:microsoft-icon",
          style: {
            backgroundColor: "transparent",
          },
        },
        {
          skillName: "Kanban",
          fontAwesomeClassname: "material-symbols:view-kanban",
          style: {
            backgroundColor: "transparent",
            color: "#127475",
          },
        },
        {
          skillName: "Jira",
          fontAwesomeClassname: "devicon:jiraalign",
          style: {
            backgroundColor: "transparent",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/layman_brother/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Codechef",
      iconifyClassname: "simple-icons:codechef",
      style: {
        color: "#5B4638",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    },
    {
      siteName: "Codeforces",
      iconifyClassname: "simple-icons:codeforces",
      style: {
        color: "#1F8ACB",
      },
      profileLink: "http://codeforces.com/profile/layman_brother",
    },
    {
      siteName: "Hackerearth",
      iconifyClassname: "simple-icons:hackerearth",
      style: {
        color: "#323754",
      },
      profileLink: "https://www.hackerearth.com/@ashutosh391",
    },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/laymanbrother",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Private Technological University of Santa Cruz",
      subtitle: "Electronics and Automation Systems Engineering",
      logo_path: "utepsa.png",
      alt_name: "UTEPSA",
      duration: "2014 - 2018",
      descriptions: [
        "⚡ My university education primarily focused on Electronics, industrial automation and the programming of intelligent systems, equipping me with the skills to design and implement advanced automated solutions.",
        "⚡ I further developed my proficiency in microcontrollers and robotics, which are integral to modern automation processes.",
        "⚡ Building on my early start in coding at the age of 14, my university education allowed me to further improve my programming skills. I gained a comprehensive understanding of various languages like C#, C++ and Python, which are crucial for developing intelligent systems.",
      ],
      website_link: "https://v3.utepsa.edu/",
    },
    {
      title: "Autonomous University Gabriel René Moreno",
      subtitle: "Diploma in Higher Education and ICTs (Information and Communication Technologies)",
      logo_path: "uagrm.png",
      alt_name: "UAGRM",
      duration: "2020 - 2020",
      descriptions: [
        "⚡ I have specialized in the use of Information and Communication Technologies (TICs) for education, leveraging these tools to create engaging and interactive learning environments.",
        "⚡ My experience in university teaching has allowed me to develop a deep understanding of educational processes, enhancing my ability to effectively impart knowledge and facilitate learning.",
        "⚡ Additionally, I have gained proficiency in using Moodle Learning Management System (LMS) for eLearning, enabling me to design and manage effective online courses.",
      ],
      website_link: "https://www.uagrm.edu.bo/",
    },
    {
      title: "University Carlos III of Madrid",
      subtitle: "Master’s Degree in Robotics and Automation",
      logo_path: "uc3m.png",
      alt_name: "UAGRM",
      duration: "2020 - 2022",
      descriptions: [
        "⚡ With a strong foundation in the Robot Operating System (ROS) and robotics, I’ve developed the ability to design and implement complex robotic systems.",
        "⚡ My proficiency in Linux, C++, and Python has been instrumental in automating processes, demonstrating my technical versatility.",
        "⚡ My research skills, coupled with my knowledge of automation, have enabled me to explore innovative solutions and contribute to advancements in the field.",
      ],
      website_link: "https://www.uc3m.es/Inicio",
    },
    {
      title: "Autonomous University of Barcelona",
      subtitle: "Master’s Degree in Business Administration and Management",
      logo_path: "uab.png",
      alt_name: "UAGRM",
      duration: "2023 - 2024",
      descriptions: [
        "⚡ My skills in management have been honed through practical experience, enabling me to effectively oversee projects, allocate resources, and lead teams towards achieving set objectives.",
        "⚡ In terms of administration, I’ve developed the ability to organize, plan, and implement strategies that ensure smooth operations and contribute to the overall success of any endeavor.",
        "⚡ Furthermore, my understanding of both management and administration has equipped me with the strategic thinking necessary to make informed decisions that align with organizational goals and enhance operational efficiency.",
      ],
      website_link: "https://www.uab.cat/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Computer Systems and Networks",
      subtitle: '- Alternative Education Center "America de Fe y Alegría"',
      logo_path: "americaFeAlegria.jpg",
      certificate_link:
        "https://formaciontecnicabolivia.org/ceas/centro-de-educacion-alternativa-america-de-fe-y-alegria",
      alt_name: "America de Fe y Alegría",
      color_code: "#8C151599",
    },
    {
      title: "PLC Programming from Scratch with RSLogix500",
      subtitle: "- Luis Cruz",
      logo_path: "udemy.png",
      certificate_link:
        "https://www.udemy.com/course/programacion-desde-cero-de-plc-con-rslogix500/",
      alt_name: "Udemy",
      color_code: "#00000099",
    },
    {
      title: "Siemens S7-1200 PLC Programming",
      subtitle: "- Autonomous University Gabriel René Moreno",
      logo_path: "uagrm.png",
      certificate_link:
        "https://www.uagrm.edu.bo/",
      alt_name: "UAGRM",
      color_code: "#0C9D5899",
    },
    {
      title: "Leadership and Entrepreneurship",
      subtitle: "- Private Technological University of Santa Cruz",
      logo_path: "utepsa.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "UTEPSA",
      color_code: "#1F70C199",
    },
    {
      title: "Industrial Electrical Installations",
      subtitle: "- Megatron",
      logo_path: "megatron.jpeg",
      certificate_link:
        "https://www.linkedin.com/company/megatron-ingenier%C3%ADa/",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    {
      title: "Expert in Industrial Automation and Sensors",
      subtitle: "- Autonomous University Gabriel René Moreno",
      logo_path: "uagrm.png",
      certificate_link:
        "https://www.uagrm.edu.bo/",
      alt_name: "UAGRM",
      color_code: "#1F70C199",
    },
    {
      title: "G4 - Smart Home",
      subtitle: "- Ahmed Elnhas",
      logo_path: "udemy.png",
      certificate_link:
        "https://www.udemy.com/course/g4-smart-home-tutorials/",
      alt_name: "Udemy",
      color_code: "#0C9D5899",
    },
    {
      title: "How to Create SCADA/HMI Projects with Intouch",
      subtitle: "- Udemy",
      logo_path: "udemy.png",
      certificate_link:
        "https://www.udemy.com/",
      alt_name: "Udemy",
      color_code: "#00000099",
    },
    {
      title: "Industrial Automation Using Contactors",
      subtitle: "- Autonomous University Gabriel René Moreno",
      logo_path: "uagrm.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "UAGRM",
      color_code: "#2A73CC",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "Kevin Medrano brings a powerful combination of technical expertise, leadership potential, and project management skills to the table. With nearly three years of experience, he has a proven track record of success in collaborating with various organizations to deliver impactful projects.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Robotics and Automation Maintenance Trainee",
          company: "Nissan Motor Corporation",
          company_url: "https://www.nissan-global.com/EN/",
          logo_path: "nissan_motor.png",
          duration: "Sep 2023 - Currently working",
          location: "Ávila, Spain",
          description:
            "As an Automation and Robotics Engineer, I am working in PLC programming, particularly in Rockwell and Siemens systems. I am leveraging my experience with AGVs (Automated Guided Vehicles) and passion for Industry 4.0 to design, develop, and implement innovative solutions that improve efficiency and productivity in robotic welding lines. My work continuously combines automation with innovation to ensure smooth operations and optimized results.",
          color: "#0879bf",
        },
        {
          title: "Chief Technology Officer",
          company: "Refeeling",
          company_url: "https://www.linkedin.com/company/refeeling/",
          logo_path: "refeeling_logo.png",
          duration: "Sep 2022 - Feb 2023",
          location: "Madrid, Spain",
          description:
            "As part of an innovative startup focused on intelligent packaging reuse solutions, I collaborated with a talented team. Utilizing skills in electronics, project management, and programming, we made significant strides in sustainable packaging development. Despite the startup ceasing operations, the invaluable experience provided insights into industry challenges and honed teamwork, problem-solving, and adaptability skills for future endeavors.",
          color: "#9b1578",
        },
        {
          title: "Project Engineer",
          company: "Greennova Bolivia",
          company_url: "https://www.facebook.com/greennovabolivia/",
          logo_path: "greennova.png",
          duration: "Feb 2020 - Nov 2021",
          location: "Santa Cruz, Bolivia",
          description:
            "As a professional, I have been involved in documenting project progress, setting up tests, and ensuring compliance with guidelines and deadlines. I have focused on projects related to BMS, home automation, and smart homes, using technologies such as Smart G4, Google Home, and Alexa. My skills span areas such as automation, process automation, interpersonal skills, electricity, information technology, engineering, Python, and communication protocols.",
          color: "#fc1f20",
        },
        {
          title: "Freelancer",
          company: "Bittron",
          /*company_url: "https://www.facebook.com/greennovabolivia/",*/
          logo_path: "bittron.png",
          duration: "Ene 2018 - Feb 2020",
          location: "Santa Cruz, Bolivia",
          description:
            "I leveraged my extensive experience in PLC programming using Siemens and Allen Bradley Micrologix 500 series controllers. I also possessed proficiency in working with PIC, AVR, and STM32 microcontrollers. My skillset further expanded to include Raspberry Pi utilization for various projects, along with expertise in CCTV security systems and access control solutions.",
          color: "#fc1f20",
        },
        {
          title: "Technical Support Engineer",
          company: "Kiev SRL",
          company_url: "https://kiev-srl.com/es/",
          logo_path: "kiev.png",
          duration: "Mar 2019 - Oct 2019",
          location: "Santa Cruz, Bolivia",
          description:
            "I specialized in vending machine system development and provided technical support for ATMs, leveraging my skills in automation, maintenance, C++, Python, understanding communication protocols like MDB, and working with microcontrollers.",
          color: "#fc1f20",
        },
        {
          title: "Laboratory Technical Assistant",
          company: "UTEPSA University",
          company_url: "https://www.facebook.com/ElectronicaySistemasUtepsa/",
          logo_path: "utepsa_ex.png",
          duration: "Jul 2015 - Dic 2016",
          location: "Santa Cruz, Bolivia",
          description:
            "Throughout my professional career, I have meticulously organized, installed, calibrated, and maintained all work instruments and equipment.  My expertise extended to supporting the conduct of experiments, tests, and analyses for research and project development. Additionally, I specialized in the maintenance and repair of electronic laboratory equipment.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Technical Service Engineer",
          company: "Balanza Sanchez SRL",
          company_url: "https://www.facebook.com/balanzassanchez/",
          logo_path: "bs.png",
          duration: "Feb 2018 - Mar 2019",
          location: "Santa Cruz, Bolivia",
          description:
            "I specialized in vending machine system development and provided technical support for ATMs, leveraging my skills in automation, maintenance, C++, Python, understanding communication protocols like MDB, and working with microcontrollers.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "Jul 2021 - PRESENT",
          location: "Work From Home",
          description:
            "I am contributing to opensource projects. Includes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
        {
          title: "Club Programming Leader",
          company: "Udabol University",
          company_url: "https://virtual.udabol.edu.bo/",
          logo_path: "udabol.png",
          duration: "Mar 2015 - Dic 2016",
          location: "Santa Cruz, Bolivia",
          description:
            "As the leader and organizer of a computer programming club, I had the privilege of guiding enthusiasts into the world of coding. My role involved fostering creativity, teaching technical skills, and cultivating a community passionate about technology. Through this volunteer work, I experienced the transformative power of education and collaboration in the technological field.",
          color: "#D83B01",
        },
        {
          title: "Catechism Coordinator",
          company: "Hombres Nuevos",
          company_url:
            "https://www.hombresnuevos.org/",
          logo_path: "hn.png",
          duration: "Feb 2013 - Sep 2016",
          location: "Santa Cruz, Bolivia",
          description:
            "Within the church, I actively collaborated as a catechism and youth group coordinator.  In this role, I guided and organized activities that fostered spiritual growth and community engagement.",
          color: "#0C9D58",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "I'm a strong advocate for Industry 4.0 through intelligent automation. My expertise lies in designing and implementing data-driven automation solutions to optimize industrial processes.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "0",
      name: "Design and implementation of a greenhouse automated and supervised via web for the strawberry production",
      createdAt: "2019-07-02T16:26:54Z",
      description: "Final degree project published at Utepsa University",
      url:
        "https://drive.google.com/file/d/17WvNqtWJnqm8aRiggqMNRSSGOgNm_ugh/view?usp=sharing",
    },
    {
      id: "1",
      name: "Learning the utility of objects by observing movement human and object",
      createdAt: "2022-03-12T14:00:00Z",
      description: "Master's final project published at Carlos III University",
      url:
        "https://drive.google.com/file/d/1bUAlzQKzbqSxcNdR_02LZzxRtO8JZrWF/view?usp=sharing",
    },
    {
      id: "2",
      name: "Advanced System for Enhancing Location Identification through Human Pose and Object Detection",
      createdAt: "2023-08-18T13:00:54Z",
      description: "Paper Written on Machines published in MDPI",
      url:
        "https://www.mdpi.com/2075-1702/11/8/843",
    },
    
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.png",
    description:
      "I am available on almost every social media. You can message me, I will reply ASAP. I can help you with Industry 4.0, Industrial Automation, Robotics, ML and AI.",
  },
  blogSection: {
    title: "Kevin Medrano Ayala",
    subtitle:
      "For any inquiries, questions, or assistance you might need, don’t hesitate to get in touch with me. I’m here to help.",
    //link: "https://blogs.ashutoshhathidara.com/",
    //avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ávila, Spain - Castilla y Leon",
    locality: "Ávila",
    country: "ES",
    region: "Castilla y Leon",
    postalCode: "05003",
    streetAddress: "Calle Hornos Caleros 58",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/7ETvBsnVMt8wZHxSA",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  illustration,
  splashScreen,
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
